import { connect } from "react-redux";
import { tokenChange } from "../../action/Navigation";
import Storage from "@datenbanker/storage";
const storage = new Storage();

const data = store => {
  console.log(store);
  return {
    pages: store.navigation.pages
  };
};

const actions = dispatch => {
  return {
    changeTokens: tokens => {
      storage.set("user:accessToken", tokens.accessToken);
      storage.set("user:idToken", tokens.idToken);
      storage.set("user:refreshToken", tokens.refreshToken);
      dispatch(tokenChange(tokens));
    }
  };
};

export default component =>
  connect(
    data,
    actions
  )(component);
