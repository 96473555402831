import { createStore, combineReducers, applyMiddleware } from "redux";
import reducer from "./reducer";
import middleware from "./middleware";
export default createStore(
  combineReducers(reducer),
  {},
  applyMiddleware(store => next => action => {
    if (action.type && middleware[action.type]) {
      return middleware[action.type](store, next, action);
    } else {
      return next(action);
    }
  })
);
