import { faBook } from "@fortawesome/pro-light-svg-icons/faBook";
import { faCubes } from "@fortawesome/pro-light-svg-icons/faCubes";
import { faProjectDiagram } from "@fortawesome/pro-light-svg-icons/faProjectDiagram";
import { faReact } from "@fortawesome/free-brands-svg-icons";

const store = {
  pages: [
    {
      name: "Projekte",
      icon: faProjectDiagram,
      layout: "admin",
      component: () => {
        return import("../resources/component/Documentation/Api");
      },
      group: "private",
      protected: true
    },
    {
      name: "Dokumentation",
      icon: faBook,
      layout: "admin",
      group: "private",
      protected: true,
      type: "list",
      elements: [
        {
          name: "Api",
          icon: faCubes,
          protected: true,
          layout: "admin",
          component: () => {
            return import("../resources/component/Documentation/Api");
          },
          group: "private",
          path: "/documentation/api",
          aliasPath: ["/"]
        },
        {
          name: "React Integration  ",
          icon: faReact,
          protected: true,
          layout: "admin",
          component: () => {
            return import("../resources/component/Documentation/Api");
          },
          group: "private",
          path: "/documentation/react"
        }
      ]
    }
  ]
};

export default (state = store, action) => {
  let newState = { ...state };
  switch (action.type) {
    case "SIGN_IN":
    default:
      return newState;
  }
};
