import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { DevCloud } from "@datenbanker/devcloud-client-lib";
import { Theme } from "@datenbanker/react-devcloud-components";

import store from "./store";

DevCloud.init({
  apiToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiI3YWQ1OGQ0NS0wODM2LTRkYjAtOGY0ZS05YmMyZmE2NzM1YmUiLCJzY29wZXMiOlsiNjlhNjYxNmQtMTM1Yy00YmJjLWE2N2ItY2Q1NjAxMWUyYWRmIl19.ftg8ErB5nQD0VpdrPlGVMqN5uaHYEeI00Bvd6ukbDSM",
  services: {
    authentication: {
      user: {
        pool: ["8d955e8c-e539-4fd5-a4ab-8b4e1d6a627e"] //0231a2b2-e877-4636-86d2-1e20b4d91838
      }
    }
  },
  handler: {} // handler assigend in app component because they need redux
});
Theme.init();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
